import React from 'react';
import { useState,useEffect } from 'react';

const MainFooter = () => {
  return (
    <div>
    <footer id="tt-footer" className="no-margin">
      <div className="footer-wrapper">
        <div className="container">
          <nav className="f-nav" id="f-nav">
            <ul>
              <li><a href="index">Home</a></li>
              <li><a href="about">About</a></li>
              <li><a href="events">Events</a></li>
              {/* <li><a href="gallery">Gallery</a></li> */}
              <li><a href="parties">Parties</a></li>
              <li><a href="contact">Contact Us</a></li>
            </ul>
          </nav>
          <div className="f-col">
            <div className="row">
              <div className="col-md-4">
                <div className="f-info">
                  <div className="f-info__icon icon-place"></div>
                  <div className="f-info__content">
                    <div className="tt-title">Address</div>
                    <address>
                      A4-UG1-8 Publika, Solaris Dutamas<br />
                      No 1 Jalan Dutamas 50480<br />
                      Kuala Lumpur Malaysia
                    </address>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="f-info">
                  <div className="f-info__icon icon-phone"></div>
                  <div className="f-info__content">
                    <div className="tt-title">Contact</div>
                    <address>
                      +60 12 - 5380551<br />
                      info@tomorrowlandclub.com
                    </address>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="f-info">
                  <div className="f-info__icon icon-clock"></div>
                  <div className="f-info__content">
                    <div className="tt-title">Working Hours</div>
                    <address>
                      Everyday: 5.00 pm - 3.00 am
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="f-social">
            <ul>
              <li><a href="https://fb.com/tomorrowlandclubpublika.kl" className="icon-social-facebook" target="_blank" rel="noopener noreferrer"></a></li>
              <li><a href="https://x.com/Tomorrowla65311" className="icon-social-twitter" target="_blank" rel="noopener noreferrer"></a></li>
              <li><a href="https://www.instagram.com/tomorrowland.publika" className="icon-social-instagram" target="_blank" rel="noopener noreferrer"></a></li>
            </ul>
          </div>
          <div className="f-copyright">
            &copy; 2022 Tomorrowland. All Rights Reserved.<br />
            <a href="#">Privacy Policy</a> &nbsp;|&nbsp; <a href="#">Terms and Condition</a>
          </div>
        </div>
      </div>
    </footer>
    </div>
  )
}

export default MainFooter