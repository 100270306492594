import React, { useEffect, useState } from 'react';
import logo from './../../images/logo.png';


const MainHeader = () => {
const [showdroupdown , setShowdroupdown] = useState(false);

const [path, setPath]= useState('');
useEffect(()=>{
  setPath(window.location.href);
});
  return (
    <div>

<header id="tt-header" className="align-items-center">
  <div className="tt-holder">
    <div className="tt-col">
      {/* logo */}
      <a href="index.html" className="tt-logo tt-logo-alignment">
        <img src={logo} alt="Logo" />
      </a>
      {/* /logo */}
    </div>
    <div className="tt-col tt-desctop-parent tt-wide">
      {/* desktop-nav */}
      <nav id="tt-nav" className="defined-item">
        <ul>
          <li><a href="home" className={path.includes('home') && 'activeheader'}><span>HOME</span></a></li>
          <li><a href="about" className={path.includes('about') && 'activeheader'}><span>ABOUT</span></a></li>
          <li><a href="events" className={path.includes('events') && 'activeheader'}><span>EVENTS</span></a></li>
          {/* <li><a href="gallery"><span>GALLERY</span></a></li> */}
          <li><a href="parties" className={path.includes('parties') && 'activeheader'}><span>PARTIES</span></a></li>
          <li><a href="contact" className={path.includes('contact') && 'activeheader'}><span>CONTACT US</span></a></li>
        </ul>
        {/* <div id="tt-nav__line" style={{ top: '29px', left: '13px', width: '41px' }}></div> */}
      </nav>
      {/* /desktop-nav */}
    </div>
    <div className="tt-col">
      <ul className="nav-btn">
        <li>
          <a href="https://api.whatsapp.com/send/?phone=60125380551&amp;text&amp;app_absent=0" data-toggle="modal" data-target="#modalPrivateEvent">
            <i className="icon-private"></i>
            <span className="tt-text">Private Events<span className="short-hide"></span></span>
          </a>
        </li>
        <li>
          <a href="https://api.whatsapp.com/send/?phone=60125380551&amp;text&amp;app_absent=0" data-toggle="modal" data-target="#modalVipTables">
            <i className="icon-tables"></i>
            <span className="tt-text"><span className="short-hide"></span> Rev</span>
          </a>
        </li>
        <li>
          <a href="https://api.whatsapp.com/send/?phone=60125380551&amp;text&amp;app_absent=0" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-whatsapp"></i>
            <span className="tt-text"><span className="short-hide"> </span> Chat</span>
          </a>
        </li>
      </ul>
    </div>
    <div className="tt-col tt-col-obj">
      <div className="tt-obj tt-obj-toggle">
        <a href="javascript:void(0)" onClick={(e)=>{setShowdroupdown(showdroupdown ? false : true)}}  className="tt-menu-toggle icon-menu icon-menu-toggle"></a>
        
      </div>
    </div>
  </div>
  
{showdroupdown &&
      <ul style={{
        textAlign: 'center',
        backgroundColor: '#212529'}}>
        <li onClick={(e)=>setShowdroupdown(false)} className="mm-close-parent">
          Close
        </li>
        <li>
          <a href="/"><span>HOME</span></a>
        </li>
        <li>
          <a href="about"><span>ABOUT</span></a>
        </li>
        <li>
          <a href="events"><span>EVENTS</span></a>
        </li>
        {/* <li>
          <a href="gallery.html"><span>GALLERY</span></a>
        </li> */}
        <li>
          <a href="parties"><span>PARTIES</span></a>
        </li>
        <li>
          <a href="contact"><span>CONTACT US</span></a>
        </li>
      </ul>}
</header>
    </div>
  )
}

export default MainHeader;