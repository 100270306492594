import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function Influencer() {
  const { inf_Id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (inf_Id) {
      localStorage.setItem('inf_Id', inf_Id); // Store the actual inf_Id
      navigate('/');
    }
  }, [inf_Id, navigate]);

  return (
    <div>
      <h1>Influencer ID: {inf_Id}</h1> {/* Display the actual inf_Id */}
    </div>
  );
}

export default Influencer;
