import React from 'react';
import { lazy} from "react";
import './App.css';
import {Routes , Route, NavLink, Navigate } from 'react-router-dom';
import PokemonList from './containers/pokemonList';
import Pokemon from './containers/pokemon'

import './css/style.css';
import MainHeader from './components/MainHeader';
import MainFooter from './components/MainFooter';
import Influencer from './components/events/Influencer';

const Home = lazy(() => import('./components/home'));
const About = lazy(() => import('./components/about'));
const Events = lazy(() => import('./components/events'));
const Gallery = lazy(() => import('./components/gallery'));
const Parties = lazy(() => import('./components/parties'));
const Contact = lazy(() => import('./components/contact'));

function App() {
  return (
    <div>
      <MainHeader />
      <nav>
        <NavLink to={"/"}>Search</NavLink>
      </nav>
      <Routes >
        <Route path={"/"} exact Component={PokemonList}/>
        <Route path={"/pokemon/:pokemonName"} exact Component={Pokemon}/>
        <Route path="*" element={<Navigate to ="/" />}/>
        
        <Route index element={<Home />} />
        <Route key={"home"}  path="/home" element={<Home />} />
          <Route key={"about"} path="/about" element={<About />} />
          <Route key={"events"} path="/events" element={<Events />} />
          {/* <Route key={"login"} path="/gallery" element={<Gallery />} /> */}
          <Route key={"parties"} path="/parties" element={<Parties />} />
          <Route key={"contact"} path="/contact" element={<Contact />} />
          <Route path="/influencer/:inf_Id" element={<Influencer />} />
      </Routes >
      <MainFooter />
    </div>
  );
}

export default App;
